<template>
  <!-- modal config: -->
  <!-- https://codepen.io/m2de/pen/JONpmj -->
  <!-- https://github.com/adamwathan/vue-tailwind-examples/blob/master/src/views/ModalExample.vue -->
  <portal to="modals" class="relative">
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="open">
      <div
        class="flex items-center sm:items-end justify-center min-h-screen overflow-y-auto text-center sm:block sm:p-0"
      >
        <transition
          @before-leave="cardLeaving = true"
          @after-leave="cardLeaving = false"
          enter-active-class="ease-out duration-300"
          leave-active-class="ease-in duration-200"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          appear
        >
          <div
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
            v-if="modalVisible"
          >
            <div
              class="absolute inset-0 bg-gray-500 opacity-75"
              @click="close"
            ></div>
          </div>
        </transition>

        <transition-group
          @before-leave="cardLeaving = true"
          @after-leave="cardLeaving = false"
          enter-active-class="transition-all transition-fast ease-out-quad"
          leave-active-class="transition-all transition-medium ease-in-quad"
          enter-class="opacity-0 scale-70"
          enter-to-class="opacity-100 scale-100"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-70"
          tag="div"
          class="w-full"
          appear
        >
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            :key="1"
            v-if="modalVisible"
            >&#8203;</span
          >
          <!-- This element is to trick the browser into centering the modal contents. -->

          <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                  From: "opacity-100 translate-y-0 sm:scale-100"
                  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              -->
          <div
            class="min-h-screen sm:min-h-0 inline-block align-bottom bg-white sm:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full w-full"
            :class="{
              'sm:max-w-xl': size == 'small',
              'sm:max-w-2xl': size == 'normal',
              'sm:max-w-3xl': size == 'large',
            }"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            v-if="modalVisible"
            @click.self="close"
            :key="2"
          >
            <div class="bg-white p-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:flex-col sm:items-start">
                <div class="flex flex-row w-full">
                  <div
                    class="text-left sm:mt-0 sm:text-left relative w-full flex flex-row"
                  >
                    <div
                      class="rounded-full h-10 w-10 flex items-center justify-center mr-4"
                      :class="[modalIconColor]"
                      v-if="modalIcon"
                    >
                      <i :class="[modalIcon]"></i>
                    </div>
                    <h3
                      class="flex-1 text-lg leading-6 font-semibold text-gray-900 py-2"
                      id="modal-headline"
                    >
                      {{ title }}
                    </h3>
                    <div class="absolute top-0 right-0">
                      <button
                        class="w-8 h-8 bg-gray-100 flex items-center justify-center rounded-full mt-1 cursor-pointer focus:outline-none active:outline-none hover:bg-gray-300 transition transition-all"
                        v-on:click="close"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-4 mb-2 w-full">
                  <slot></slot>
                </div>
              </div>
            </div>
            <div
              class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse pb-6"
              v-if="showAcceptButton || showEscapeButton"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                v-if="showAcceptButton"
                @click="$emit('onSuccess')"
                :disabled="acceptButtonProgress"
              >
                <span v-if="acceptButtonProgress"
                  ><i class="fa fa-circle-notch fa-spin text-white"></i
                ></span>
                <span v-if="!acceptButtonProgress">
                  {{ acceptButtonText }}</span
                >
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                v-if="showEscapeButton"
                @click="close"
              >
                {{ escapeButtonText }}
              </button>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    modalIcon: {
      type: String,
      default: null,
    },
    modalIconColor: {
      type: String,
      default: "bg-blue-100 text-blue-700",
    },
    title: {
      type: String,
      default: "Modal",
    },
    size: {
      type: String,
      default: "normal",
    },
    escapeButtonText: {
      type: String,
      default: "Cancel",
    },
    showEscapeButton: {
      type: Boolean,
      default: false,
    },
    acceptButtonText: {
      type: String,
      default: "DEFAULT",
    },
    showAcceptButton: {
      type: Boolean,
      default: true,
    },
    acceptButtonProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalVisible: true,
      backdropLeaving: false,
      cardLeaving: false,
    };
  },
  created() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close();
      }
    };

    document.addEventListener("keydown", onEscape);

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });

    this.$on("closeModalEvent", () => {
      this.close();
    });
  },
  watch: {
    open(newValue) {
      if (newValue === true) {
        this.modalVisible = true;
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  methods: {
    close() {
      // console.log();
      if (this.modalVisible == false) return;
      this.modalVisible = false;
      // Wait for animation to stop before removing modal container completely
      setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
  },
};
</script>
